
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  ComputedRef,
  onUnmounted
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSubscriptions } from '@/composables/api';
import SearchBox from '@/components/keyword-searching/Index.vue';
import UnsubscribeDialog from './components/UnsubscribeDialog.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import {
  DEFAULT_PAGE_SIZE,
  MAX_PER_PAGE,
  ResponseError,
  getPlans
} from '@/services/api';
import { Plan } from '@/interfaces/Plan';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { SubscriptionSource, SubscriptionStatus } from '@/interfaces';
import { getThousandSeparator, getStatusTagType } from '@/utils/render';
import permissionUnits from '@/components/permission-units/index.vue';
import { usePage, useKeyword } from '@/composables';
import { cloneDeep, get } from 'lodash';
import { DEFAULT_CANCEL_SUBSCRIPTION_DATA } from './constants';
import { ElMessage } from 'element-plus';

const translate = {
  PLAN: '會員訂閱',
  COUPON: '優惠券',
  TESTING: '後台建立'
};

export default defineComponent({
  components: {
    SearchBox,
    Filter,
    permissionUnits,
    UnsubscribeDialog
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const OrderCreatedDateRange = ref(['']);
    const startDate = ref('');
    const endDate = ref('');
    const source = ref();
    const planId = ref();
    const plans = ref<Plan[]>([]);
    const plansOptions = ref([]);
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);
    const isUnsubscribeDialogVisible = ref(false);
    const unsubscribeData = ref(cloneDeep(DEFAULT_CANCEL_SUBSCRIPTION_DATA));

    const { data, isLoading, isFetching, refetch } = useSubscriptions({
      page,
      keyword,
      pageSize,
      startDate,
      endDate,
      source,
      planId
    });

    const fetchPlans = async() => {
      try {
        const { data } = await getPlans({ query: { pageSize: MAX_PER_PAGE } });
        plans.value = data;
      } catch (error) {
        const _err = error as ResponseError;
        ElMessage.error(_err.response?.data.message);
      }
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      planId.value = event[0];
      source.value = event[1];
    };

    const handleDateChange = () => {
      if (!OrderCreatedDateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }
      startDate.value = formatISOString(OrderCreatedDateRange.value[0]);
      endDate.value = formatISOString(OrderCreatedDateRange.value[1]);
    };

    watch(plans, () => {
      plansOptions.value =
        plans.value.map(({ name, id }) => ({ label: name, value: id }));
    });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => ([
      {
        type: FilterType.SELECTOR,
        label: '方案',
        placeholder: '請選擇',
        options: plansOptions.value
      },
      {
        type: FilterType.SELECTOR,
        label: '來源',
        placeholder: '請選擇',
        options: Object
          .entries(SubscriptionSource)
          .map(([label, value]) => ({ label: translate[label], value }))
      }
    ]));

    const handleShowCancelSubscription = (row) => {
      unsubscribeData.value = {
        planId: row.id,
        memberNo: get(row, 'user.memberNo', '-'),
        transactionId: get(row, 'order.transactionId', '-'),
        name: get(row, 'plan.name', '-'),
        startedAt: row?.startedAt ? formatLocalTime(row.startedAt) : '-',
        endedAt: row?.endedAt ? formatLocalTime(row.endedAt) : '-',
        initDiscountPrice: row.plan?.initDiscountPrice
          ? getThousandSeparator((+row.plan?.initDiscountPrice).toFixed(2).toString())
          : '-'
      };
      isUnsubscribeDialogVisible.value = true;
    };

    const getStatusInfo = (statusText: SubscriptionStatus) => {
      const info = {
        text: '',
        isDisabled: false
      };
      switch (statusText) {
        case SubscriptionStatus.SUBSCRIBING:
          info.text = '訂閱中';
          info.isDisabled = false;
          return info;
        case SubscriptionStatus.SUBSCRIBE_CANCEL:
          info.text = '訂閱取消';
          info.isDisabled = true;
          return info;
        case SubscriptionStatus.SUBSCRIBE_EXPIRE:
          info.text = '訂閱到期';
          info.isDisabled = true;
          return info;
        default:
          return info;
      }
    };

    const getCancelSubscriptionName = (row) => {
      if (row?.unsubscribedAt && !row.admin?.name) {
        return '會員';
      }
      return row.admin?.name;
    };

    watch(isUnsubscribeDialogVisible, (isVisible) => {
      if (!isVisible) {
        unsubscribeData.value = cloneDeep(DEFAULT_CANCEL_SUBSCRIPTION_DATA);
      }
    });

    // 從 queryString 帶入搜索條件
    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });

    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate as string;
        endDate.value = query.endDate as string;

        if (startDate.value && endDate.value) {
          OrderCreatedDateRange.value = [startDate.value, endDate.value];
          return;
        }
        OrderCreatedDateRange.value = null;
      },
      { immediate: true }
    );

    onMounted(() => {
      fetchPlans();
    });

    return {
      page,
      data,
      refetch,
      plans,
      isLoading,
      isFetching,
      OrderCreatedDateRange,
      filterOptions,
      plansOptions,
      SubscriptionSource,
      defaultTime,
      keyword,
      handleDateChange,
      handleFilterChange,
      formatLocalTime,
      getThousandSeparator,
      handleShowCancelSubscription,
      isUnsubscribeDialogVisible,
      getStatusInfo,
      getStatusTagType,
      unsubscribeData,
      getCancelSubscriptionName,
      setPageQuery
    };
  }
});
