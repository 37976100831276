
import { ResponseError, updateSubscription } from '@/services/api';
import { PropType, computed, defineComponent, onMounted, ref, toRefs } from 'vue';
import { DEFAULT_CANCEL_SUBSCRIPTION_DATA } from '../constants';
import { ElMessage } from 'element-plus';
import { useSubscriptionCancellation } from '@/composables';

const REASON_OPTIONS = [
  {
    label: '內容不符合預期',
    value: '內容不符合預期'
  },
  {
    label: '介面操作不順暢',
    value: '介面操作不順暢'
  },
  {
    label: '播放問題',
    value: '播放問題'
  },
  {
    label: '不常使用',
    value: '不常使用'
  },
  {
    label: '其他',
    value: '其他'
  }
];

export default defineComponent({
  props: {
    isVisible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object as PropType<typeof DEFAULT_CANCEL_SUBSCRIPTION_DATA>,
      required: true
    }
  },
  emits: ['close', 'refetch', 'update:isVisible'],
  setup(props, { emit }) {
    const { isVisible, data } = toRefs(props);

    const syncIsVisible = computed({
      get() {
        return isVisible.value;
      },
      set(val) {
        emit('update:isVisible', val);
      }
    });

    const reason = ref('');
    const reasonMessage = ref('');

    const cancelSubscriptionLoading = ref(false);
    const { isLoading: isCancellationLoading, mutate: subscriptionCancellation } = useSubscriptionCancellation();
    const fetchCancelSubscription = async() => {
      cancelSubscriptionLoading.value = true;
      const cancelReason = reason.value === '其他' ? `其他：${reasonMessage.value}` : reason.value;

      subscriptionCancellation({
        id: data.value.planId,
        data: { cancelReason }
      }, {
        onSuccess: () => {
          ElMessage.success('取消訂閱成功');
          emit('refetch');
        },
        onError: (error) => {
          const _err = error as ResponseError;
          ElMessage.error(_err.response?.data.message);
        },
        onSettled: () => {
          cancelSubscriptionLoading.value = false;
          syncIsVisible.value = false;
        }
      });
    };

    const isSubmitDisabled = computed(() => {
      if (reason.value === '其他') {
        return !reasonMessage.value;
      }
      return !reason.value;
    });

    return {
      REASON_OPTIONS,
      syncIsVisible,
      reason,
      reasonMessage,
      cancelSubscriptionLoading,
      isSubmitDisabled,
      fetchCancelSubscription
    };
  }
});
